
import Vue from 'vue'
export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    WithdrawalWrite: () =>
      import('@/components/views/mypage/WithdrawalWrite.vue'),
  },
})

interface Data {}
interface Methods {}
interface Computed {}
interface Props {}
